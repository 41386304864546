import Card from "./Card";
import s from "./Services.module.css";

const Services = () => {
  return (
    <div className={s.services} id="services">
      {/* ref на данный раздел */}
      <div className={s.container}>
        <span className={s.servicesTitle}>Наши услуги</span>
        <Card />
      </div>
    </div>
  );
};

export default Services;
