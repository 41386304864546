import s from "./Contact.module.css";
import { BiPhone } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";

function Contact() {
  return (
    <div className={s.map} id="contact">
      <div className={s.container}>
        <div className={s.mapInner}>
          <div className={s.mapContent}>
            <div className={s.mapTitle}>Контакты</div>
            <div className={s.contactInfo}>
              <div className={s.adress}>
                <a target="_blank" href="https://go.2gis.com/uhsiy">
                  <FaMapMarkerAlt className={s.icon} />
                  Казахстан, город Алматы, Бостандыский район,
                  <br /> Проспект Аль-Фараби, строение 15,
                  <br /> почтовый индекс 050059
                </a>
              </div>

              <div className={s.phone}>
                <a href="tel:+77054048844">
                  <BiPhone className={s.icon} />
                  +7-(705)-404-88-44
                </a>
              </div>

              <div className={s.email}>
                <a href="info@ay-money.kz">
                  <HiOutlineMail className={s.icon} />
                  info@ay-money.kz
                </a>
              </div>
            </div>
          </div>

          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac3b182b9e65ebe88f955e31d68621e75e606c9c6b4a17305b34776936cf3c76b&amp;source=constructor"
            width="800"
            height="400"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
