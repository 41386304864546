import React, { useState, useEffect } from "react";
import s from "./Slider.module.css";

import one from "../../assets/Slider/1.webp";
import two from "../../assets/Slider/2.webp";
import three from "../../assets/Slider/3.webp";

const Slider = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    { src: one, caption: "" },
    { src: two, caption: "" },
    { src: three, caption: "" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePrevSlide = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  return (
    <div className={s.slider}>
      <div className={s.slideButtons}>
        <button className={s.prevButton} onClick={handlePrevSlide}>
          &lt;
        </button>
        <button className={s.nextButton} onClick={handleNextSlide}>
          &gt;
        </button>
      </div>
      <div className={s.slideIndicators}>
        {images.map((_, index) => (
          <div
            key={index}
            className={`${s.indicator} ${
              index === currentImage ? s.active : ""
            }`}
          />
        ))}
      </div>
      <div className={s.slidesContainer}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`${s.slide} ${
              index === currentImage ? s.currentSlide : ""
            }`}
            style={{
              backgroundImage: `url(${image.src})`,
            }}
          >
            <div className={s.caption}>{image.caption}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
