import s from "./Popup.module.css";

const Popup = ({ popups, closeModal }) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className={s.about}>
        <span className={s.modelClose} onClick={closeModal}>
          &times;
        </span>
        <div className={s.container}>
          <h3 className={s.aboutTitle}>{popups.title}</h3>
          <div className={s.photo}>
            <img src={popups.photo} />
          </div>
          <div className={s.aboutInfo}>
            <p>{popups.infoTextOne}</p>
            <p>{popups.infoTextTwo}</p>
            <p>{popups.infoTextThree}</p>
            <p>{popups.infoTextFour}</p>
            <p>{popups.infoTextFive}</p>
            <p>{popups.infoTextSix}</p>
            <p>{popups.infoTextSeven}</p>
            <p>{popups.infoTextEigth}</p>
            <p>{popups.infoTextNine}</p>
            <p>{popups.infoTextTen}</p>
          </div>
        </div>

        {/* ========================================================== */}
      </div>
    </div>
  );
};

export default Popup;
