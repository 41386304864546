import React, { useEffect, useState } from "react";
import s from "./Card.module.css";
import Acquiring from "../../assets/Popup/acquiring.webp";
import Cards from "../../assets/Popup/cards.webp";

import Modal from "./Modal";
import Popup from "./Popup";
import acquiring from "../../assets/Modal/acquiring.webp";
import cards from "../../assets/Modal/cards.webp";

const state = {
  cards: [
    {
      id: 1,
      title: "Интернет-эквайринг",
      descriptionOne:
        "Техническая возможность быстрого приема платежей в рамках интернет-эквайринга без дополнительных настроек путем формирования платежной ссылки через собственный личный кабинет.",
      image: Acquiring,

      popups: {
        title: "Интернет-эквайринг",
        photo: acquiring,
        infoTextOne:
          "Техническая возможность быстрого приема платежей в рамках интернет-эквайринга без дополнительных настроек путем формирования платежной ссылки через собственный личный кабинет.",
        infoTextTwo: "Данный метод позволит вашей компании:",
        infoTextThree: "— Увеличить прибыль, расширив способы оплаты",
        infoTextFour:
          "— Расширить географию продаж, обслуживая клиентов со всего мира",
        infoTextFive:
          "— Нарастить объем продаж за счет импульсивных покупок потребителей",
        infoTextSix:
          "— Снизить издержки по сбору и обработке наличных денежных средств",
        infoTextSeven:
          "— Подключение с минимальным комплектом документов (первоначально можно по сканам)",
        infoTextEigth: "— Удобный личный кабинет",
        infoTextNine: "— Гарантия высокого уровня безопасности",
        infoTextTen: "— Надежная техническая поддержка 24/7",
      },
    },
    {
      id: 2,
      title: "Выплаты на локальные и международные карты",
      descriptionOne:
        "Выплаты на локальные карты Республики Казахстан и на Международные карты более чем 30 стран мира.",
      image: Cards,

      popups: {
        title: "Выплаты на локальные и международные карты",
        photo: cards,
        infoTitle: "Выплаты на локальные и международные карты",
        infoTextOne:
          "Выплаты на локальные карты Республики Казахстан и на Международные карты более чем 30 стран мира.",
        infoTextTwo: "— Высокая конверсия на выплатном канале",
        infoTextThree: "— Надежная техническая поддержка 24/7",
        infoTextFour: "— Простая интеграция по нескольким выплатным методам",
      },
    },
  ],
};

const Card = () => {
  const [modalActive, setModalActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === "Escape") {
        document.body.style.overflow = "visible";
        setSelectedItem(null);
        setModalActive(false);
      }
    }
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  const openModal = (popups) => {
    document.body.style.overflow = "hidden";
    setModalActive(true);
    setSelectedItem(popups);
  };

  const closeModal = () => {
    document.body.style.overflow = "visible";
    setModalActive(false);
    setSelectedItem(null);
  };

  return (
    <div className={s.vertical}>
      <div className={s.verticalBody}>
        <div className={s.verticalRow}>
          {state.cards.map((card) => (
            <div key={card.id} className={s.verticalColumn}>
              <div className={s.verticalItem}>
                <div className={s.verticalSubtitle}>{card.title}</div>
                <div className={s.verticalLabel}>
                  <img src={card.image} alt="" />
                </div>
                <div className={s.verticalText}>
                  <p>{card.descriptionOne}</p>
                </div>

                <button
                  onClick={() => openModal(card)}
                  className={s.verticalBtn}
                >
                  Подробнее
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal active={selectedItem !== null} modalActive={modalActive}>
        {selectedItem && (
          <Popup popups={selectedItem.popups} closeModal={closeModal} />
        )}
      </Modal>
    </div>
  );
};

export default Card;
