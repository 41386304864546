import s from "./About.module.css";
import team from "../../assets/About/team.webp";

function About() {
  return (
    <div className={s.about} id="about">
      <div className={s.container}>
        {/* ref на данный раздел */}
        <div className={s.aboutInner}>
          <div className={s.aboutContent}>
            <h1 className={s.aboutTitle}>О компании</h1>
            <div className={s.aboutInfo}>
              <p>
                Мы ставим перед собой задачу улучшить качество жизни людей,
                создавая технологические решения. В рамках электронной
                коммерции, интернет-эквайринг играет важную роль.
              </p>
              <p>
                Наша работа основывается на принципах безопасности, качества и
                гибкости. Мы стремимся к индивидуальному подходу к каждому
                клиенту.
              </p>
              <p>
                Мы являемся провайдером платежных услуг для различных
                организаций, включая торгово-сервисные предприятия, банки и
                других участников электронной коммерции.
              </p>
              <p>
                ТОО ARE YOU MONEY (АЮ МАНИ) полностью соответствует требованиям
                Платежных систем и Международным стандартам безопасности PCI
                DSS. Мы активно развиваем инфраструктуру платежных сервисов и
                банковских продуктов как в Казахстане, так и за его пределами.
              </p>
              <p>Наш БИН: 230240047961.</p>
              <p>
                Лицензия № 02-23-161 от 03.07.2023. Выдана национальным Банком
                Республики Казахстан.
              </p>
            </div>
          </div>

          <img className={s.aboutImage} src={team} alt="Изображение Команды" />
        </div>
        <span className={s.aboutTitle}>Юридическая информация</span>
        <div className={s.documents}>
          <div className={s.aboutPrivacyPolicy}>
            <a href="https://ay-money.kz/privacy-policy.pdf" target="_blank">
              Политика конфиденциальности
            </a>
          </div>
          <div className={s.aboutOferta}>
            <a href="https://ay-money.kz/oferta.pdf" target="_blank">
              Публичный договор оферты
            </a>
            <div className={s.aboutIicense}>
              <a href="https://ay-money.kz/license.pdf" target="_blank">
                Лицензия
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
